/* --------------------------------------------------------------------
Import accessibility helpers
--------------------------------------------------------------------*/
import { toggleAriaExpanded, addTabIndex, removeTabIndex  } from './accessibility.js';
import { hideHeader, showHeader, hideBackToTop, showBackToTop  } from './tools.js';


/* --------------------------------------------------------------------
Handle Burger Menu
--------------------------------------------------------------------*/
const bodyTag   = document.getElementsByTagName("body");
const mainTag   = document.getElementById("main-content");
const burger    = document.getElementsByClassName('menu');
const navMenu   = document.querySelector('.main-nav');
const closeBtn  = document.querySelector('.menu-close-btn');

let navMenuIsAnimating = false;

let isMenuOpen = false;

if( window.outerWidth > 1023 ){
    addTabIndex('has-children');
}
else{
    removeTabIndex('has-children');
}


mainTag.addEventListener("click", function() {
    if(isMenuOpen){
        closeBtn.click();
    }

}, false);

if(burger.length !== 0){
    burger[0].addEventListener("click", function() {
        if(navMenuIsAnimating){
            return true;
        }
        else{
            const burgerBtn = this;

            toggleAriaExpanded( burgerBtn );

             navMenu.classList.remove(
                'w-0',
                'hidden'
            );

            window.setTimeout(function(){
                isMenuOpen = (isMenuOpen == false) ? true : false;

                navMenu.classList.add('duration-700');
                bodyTag[0].classList.toggle("overflow-hidden");
                burgerBtn.classList.toggle("is-active");

                if(isMenuOpen){
                    navMenu.scrollTop = 0;
                    navMenu.classList.remove(
                        'lg:translate-x-full',
                        '-translate-y-full',
                        'w-0',
                        'hidden'
                    );

                    hideBackToTop();
                }
                else{
                    navMenu.classList.add('lg:translate-x-full', '-translate-y-full');
                }
            }, 5);
        }

    }, false);

    /* ------------------------------------------------------------
    Buttons, with children, shouldn't be tabbale on desktop
    ------------------------------------------------------------ */
    window.addEventListener('resize', function(){
        if( window.outerWidth > 1023 ){
            addTabIndex('has-children');
        }
        else{
            removeTabIndex('has-children');
        }
    });

    /* ------------------------------------------------------------
    List for key board input
    • If ESC key pressed, close menu
    ------------------------------------------------------------ */
    document.addEventListener("keyup", (e) => {
        if(!global.searchOpen){
            if (e.key === "Escape") {
                bodyTag[0].classList.remove("overflow-hidden");
                navMenu.classList.add(
                    'duration-700',
                    'lg:translate-x-full',
                    '-translate-y-full'
                );
                isMenuOpen = false;
            }
        }
    });

    /* ------------------------------------------------------------
    List for transition end on navigation menu, when complete
    remove transition duration.
    ------------------------------------------------------------ */
    navMenu.ontransitionrun = () => {
        navMenuIsAnimating = true;
    };

    navMenu.ontransitionend = () => {
        navMenuIsAnimating = false;
        navMenu.classList.remove('duration-700');

        if(!isMenuOpen){
            navMenu.classList.add(
                'w-0',
                'hidden'
            );
        }
    };

    /* ------------------------------------------------------------
    Close Menu Button
    ------------------------------------------------------------ */
    closeBtn.addEventListener("click", function() {
        if(navMenuIsAnimating){
            return true;
        }
        else{
            let burgerBtn = burger[0];

            bodyTag[0].classList.remove("overflow-hidden");
            navMenu.classList.add('duration-700');

            if(!isMenuOpen){
                navMenu.classList.remove(
                    'lg:translate-x-full',
                    '-translate-y-full'
                );
            }
            else{
                navMenu.classList.add(
                    'lg:translate-x-full',
                    '-translate-y-full'
                );
            }

            toggleAriaExpanded( burgerBtn );

            isMenuOpen = false;
        }

    }, false);
}

/* --------------------------------------------------------------------
Expand / Collapse main navigation sub pages
--------------------------------------------------------------------*/
const expandableBtn = document.querySelectorAll('.has-children');

if(expandableBtn){

    expandableBtn.forEach(function( button ){
        button.onclick = function(){
            button.classList.toggle('has-children');

            if( button.children[1].classList.contains('rotate-90') ){
                button.children[1].classList.remove('rotate-90');
                button.children[1].classList.add('-rotate-90');
            }
            else{
                button.children[1].classList.remove('-rotate-90');
                button.children[1].classList.add('rotate-90');
            }

            console.log('on click', button.children[1]);

            if(button.nextElementSibling){
                button.nextElementSibling.classList.toggle('hidden');
            }
        };
    });
}