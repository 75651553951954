const trigger = document.querySelectorAll('.search-trigger');
const closeBtn = document.querySelectorAll('.close-search');
const modal = document.querySelectorAll('.searchbox');

const selected_value = document.getElementById('dropdown_selected_value');
const global_searchform = document.getElementById('global-searchform');
const searchcriteria = document.getElementById('search-criteria');
const searchfield = document.getElementById('search-input');

let search_dropdown = modal[0].querySelectorAll('.dropdown');
let search_dropdown_list = modal[0].querySelectorAll('.dropdown-list');

global.searchOpen = false;

if (trigger && closeBtn) {
    trigger[0].addEventListener("click", function() {
        if (modal) {
            modal[0].classList.remove('hidden');
            searchfield.focus();
            global.searchOpen = true;

            global_searchform.addEventListener('submit', function(e){
                if(searchfield.value !== ""){
                    searchfield.classList.add('border-black');
                    searchfield.classList.remove('border-svared', 'ring-svared', 'ring-2');
                }
                else{
                    e.preventDefault();
                    searchfield.classList.remove('border-black');
                    searchfield.classList.add('border-svared', 'ring-svared', 'ring-2');
                    console.log('searchcriteria', searchcriteria.value);
                }
            });

            document.addEventListener('keyup', function(e) {
                if(e.keyCode == 27){
                    closeBtn[0].click();
                }
            });

            if(search_dropdown[0] && search_dropdown_list[0]){

                search_dropdown[0].addEventListener("click", function(e) {
                    console.log('Click 1', this, e);
                    e.preventDefault();
                    search_dropdown_list[0].classList.toggle('hidden');
                }, {
                    capture: true
                });

                let dropdownButtons =  search_dropdown_list[0].querySelectorAll('button');

                for (var i = dropdownButtons.length - 1; i >= 0; i--) {

                    dropdownButtons[i].addEventListener("click", function(e) {

                        console.log('Click 2');

                        e.preventDefault();

                        resetAriaSelected( dropdownButtons );

                        this.setAttribute("aria-selected", 'true');

                        searchcriteria.value = this.dataset.type;
                        selected_value.textContent = this.dataset.label;

                        search_dropdown_list[0].classList.add('hidden');


                    });
                };
            }
            else{
                console.error('Search Box Error: Dropdown missing.');
            }
        };
    });

    closeBtn[0].addEventListener("click", function() {
        if (modal) {
            modal[0].classList.add('hidden');
            trigger[0].focus();
            global.searchOpen = false;
        };
    });
}
else{
    console.error('Search Box Error: Missing either trigger or close button classes.');
}

function resetAriaSelected( button ){
    for (var i = button.length - 1; i >= 0; i--) {
        button[i].setAttribute("aria-selected", 'false');
    }
}