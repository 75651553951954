import { hideHeader, showHeader, hideBackToTop, showBackToTop  } from './tools.js';

/* --------------------------------------------------------------------
Hide header bar on scroll.
Reveal after period of inactivity.
-------------------------------------------------------------------- */
let hideHeaderTriggerPoint = 100;
var isScrolling, isWaiting, lastScrollPos;

window.addEventListener('scroll', function(){
    // Clear our timeout throughout the scroll
    window.clearTimeout( isScrolling );
    window.clearTimeout( isWaiting )


    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(function() {
        // Run the callback

        // Wait 20 seconds, if still in active, show header again.
        isWaiting = setTimeout(function() {
            showHeader();
        }, 10000);

    }, 66);

    // Header Show/Hide
    if(lastScrollPos > getYPosition()){
        showHeader();
    }
    else if(getYPosition() > hideHeaderTriggerPoint){
        hideHeader();
    }
    else{
       showHeader();
    }

    // Back To Top
    if(getYPosition() > hideHeaderTriggerPoint){
        showBackToTop();
    }
    else{
        hideBackToTop();
    }

    lastScrollPos = getYPosition();
});


function getYPosition(){
    var top  = window.pageYOffset || document.documentElement.scrollTop
    return top;
}

/* --------------------------------------------------------------------
Anchor links with the anchor class to the assigned target.
Requires .anchor class on a link and valud href #hash
-------------------------------------------------------------------- */
let anchors = document.getElementsByClassName('anchor');

for (var i = anchors.length - 1; i >= 0; i--) {

    anchors[i].addEventListener('click', function(e){
        e.preventDefault();

        let link = e.target;
        let anchorTarget = link.hash.replace('#','');

        if(anchorTarget){
            let header = document.getElementsByClassName( 'hdr' );
            let destination = document.getElementById( anchorTarget );
            let additionalPadding = 20;

            document.body.parentNode.scrollTo({
                top: destination.offsetTop - (header[0].offsetHeight + additionalPadding),
                left: 0,
                behavior: 'smooth'
            });
        }
        else{
            console.error('The link ['+link+'] has an anchor class but the href is not a valid hash.')
        }

    });
};