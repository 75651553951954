// Import CSS
import styles from '../css/app.pcss';

import "node_modules/@fancyapps/ui/dist/fancybox.css";
import '../css/modules/fancybox.pcss';
/* ------------------------------------------------
4X:
Accessibility Library
------------------------------------------------*/
import whatsock4x from './libs/4X/4X.Max.js';

/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
import accessibility from './modules/globals/accessibility.js';
import animations from './modules/globals/animations.js';
import burgerMenu from './modules/globals/hamburger_menu.js';

import searchBox from './modules/globals/searchbox.js';


// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});


/* ------------------------------------------------------------
Global Breadcrumb top level page interaction.
------------------------------------------------------------ */


/* ------------------------------------------------------------
Buttons, with children, shouldn't be tabbale on desktop
------------------------------------------------------------ */
function changeMainContentPadding(){
    let hdr = document.getElementsByClassName('hdr');
    let content = document.getElementById('main-content');

    if(content && hdr[0]) content.style.paddingTop = hdr[0].offsetHeight+'px';
}

window.addEventListener('resize', function(){
   changeMainContentPadding();
});

changeMainContentPadding();

/* ==========================================================================
========================================================================== */

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
